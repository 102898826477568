

// ******************************************PARTIALS FONT*********************************************************
$fontFamQuotation : 'Marck Script', 'Comic Sans MS', cursive;
$fontFamNormalText : "Roboto", Arial, sans-serif;
$fontFamTitle1 :  'Roboto Slab', Georgia, serif;
$fontFamTitle2 : 'Roboto Slab', Georgia, serif;
$fontFamTitle3 : "Roboto", Arial, sans-serif;
$fontFamTitle4 : 'Roboto Condensed', Arial, sans-serif;

// $fontFamQuotation : fantasy;
// $fontFamTitle2 : Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
// $fontFamTitle3 : 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
// $fontFamTitle4 : cursive;
