// ******************************************COLOR NAVBAR**********************************************
$navColor : #53b54b;


// **************************************NAVBAR******************************************************
.navbar{
    background-color: $navColor !important;
    // bs-navbar-padding-y: .5rem;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    }
.navbar-brand img{
    max-height: 70px;
}