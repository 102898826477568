@mixin mixinTitleBig() {
    color: $titleBigColor;
    // font-family: $fontFamTitle1;        
    font-family: $fontFamNormalText;        
}
@mixin mixinTitleParagraph() {
    color: $titleParagraph;
    // font-family: $fontFamTitle1;        
    font-family: $fontFamNormalText;        
}

// ************************POUR ESSAI TEST EN ROUGE*********************
@mixin mixinTitleMedium() {
    color: $titleBigColor;
    color: red;
    // font-family: $fontFamTitle1;        
    font-family: $fontFamNormalText;        
}

@mixin mixinContainerColor(){
    background-color: whitesmoke;
    // border: solid 0px;
}

