h6{
    color: orange !important;
}

    /* CSS POUR L'EXEMPLE */
    /* Simple reset */
*,::after,::before{
    box-sizing:border-box
}

article,aside,figcaption,figure,footer,header,hgroup,main,nav,section{
    display:block
}
// section{
//     display:block
// }
body{
    margin:0;
}

  /* Structure */
  .cConteneur {
    width:100%;
    max-width:1000px;
    margin:0 auto;
}
@media (min-width: 768px){
    .cLigne{
        display: table;
        width: 100%;
    }
    .cColonne{
        display: table-cell;
        width: 50%;
        vertical-align:middle;
    }
}

  /* Sections */
  .cSection {
    position:relative;
}
@media (min-width: 768px){
    .cSection {
        // padding: 150px 0;
        padding: 20px 0;
    }
}
.cSectionTitre {
    margin:0;
    padding:0;
    font-size:36px;
    color:#fff;
    text-align:center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);text-transform:uppercase;
}
@media (min-width: 768px){
    .cSectionTitre {
        font-size:46px;
    }
}
.cMarge {
    padding:15px;
}
@media (min-width: 768px){
    .cMarge {
        padding:50px;
    }
}

  /* Images */
  .cImageFluide,.cImageCouvre{
    width:100%;
    max-width:100%;
    height:auto;
}
@media (min-width: 768px){
    .cImageFluide{
        width:auto;
        max-width:100%;
        height:auto;
    }
    .cImageCouvre{
        height:100%;
        min-height:400px;
        object-fit:cover;
    }
}

   /* CSS POUR OBLIGATOIRE */
   /* Parallax */
   .cParallaxe1, .cParallaxe2, .cParallaxe3 {
    background-attachment: fixed;
    // background-attachment: local;
    background-position: top;
    // background-repeat: no-repeat;
    // background-size: cover;
    background-size: auto;
}

// *****************************CORRECTION IMAGE REPETEE****************************************
// **********EN DESSOUS DE 1200PX ET AU-DESSUS DE 768PX***************
@media screen and (max-width: 1200px) and (min-width: 768px) {
    .cParallaxe1, .cParallaxe2, .cParallaxe3 {
        background-attachment: local !important;          
    }
}



.cParallaxe1 {
    background-image: url('../img/parallax1.jpg');
    // min-height: 100%;
    // min-height: 100vh;
    min-height: 200px;
}
.cParallaxe2 {
    background-image: url("../img/parallax2.jpg");
    min-height: 200px;
}
.cParallaxe3 {
    background-image: url("../img/parallax3.jpg");
    min-height: 400px;
} 

/* Annule l'effet pour les mobiles et tablettes */
@media only screen and (max-device-width: 1200px) {
    .cParallaxe1, .cParallaxe2, .cParallaxe3 {
        background-attachment: scroll;
        min-height: 200px;
    }
}