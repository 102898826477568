// ****************************************************METEO********************************************************

// *******************************************IMG BACKGROUND***********************************************
.bgcMeteo{
    margin: 0;
    padding: 0;
    // background-color: #262626;
    background-image: url(../img/imgFondMeteo.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    /* background-position: center center; */
    /* background-position: unset; */    
    min-height: 100vh;
}
.widthMeteo{
    width: 300px;
}
// *****************************************BOX 300 X 300**************************************************
.box-container{
    width: 300px;
    height: 300px;
    display: flex;
    flex-wrap: wrap;
}

.box{
    width: 150px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: white;
}
// ****************************************BGC BIG TITLE***********************************************
.bgcTitleMeteo{
    background-color: rgba($color: green, $alpha: 0.30);
    // color: white !important;    
    color: rgb(32, 30, 30) !important;    
    line-height: 1.5;
    // ***************for middle place center title*********
    width: max-content;
    padding: 0.2rem;
    margin: auto;

}
.titleMeteoCenter{
    display: flex;
    // width: 600px;
    justify-content: center !important;
    align-items: center !important;
}

// *****************************************************BUTTONS************************************************
.btnColorMeteo1{
    // background-color: #01518d;
    background-color: rgba($color: rgb(110, 50, 22), $alpha: 0.80);
    color: white;
    border: none;
    font-size: 24px;    
}
.btnColorMeteo1:hover {
    background-color: rgba($color: rgb(110, 50, 22), $alpha: 0.90) !important;
    color: black !important;
  }     
//   .btnColorMeteo1.active {
//     background-color: rgba($color: rgb(25, 175, 75), $alpha: 0.90) !important;
//   } 

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
    background-color: rgba($color: rgb(110, 50, 22), $alpha: 0.99);
}

.btnColorMeteo2{
    // background-color: #01518d;
    // background-color: rgba($color: grey, $alpha: 0.70);
    // color: white;
    background-color: rgba($color: rgb(110, 50, 22), $alpha: 0.90);
    color: black;
    border: none;
    font-size: 20px;
    font-family: $fontFamTitle4;
}
.btnColorMeteo2:hover {
    // background-color: rgba($color: rgb(110, 50, 22), $alpha: 0.90) !important;
    // color: red !important;
    background-color: rgba($color: grey, $alpha: 0.70) !important;
    color: white !important;
  }     

// ****************************************OLD MODIF BUTTON COLORS************************************************  
/*This is modifying the btn-primary colors but you could create your own .btn-something class as well*/
// .btn-primary {
//     color: #fff;
//     background-color: #0495c9;
//     border-color: #357ebd; /*set the color you want here*/
// }
// .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
//     color: #fff;
//     background-color: maroon;
//     border-color: #285e8e; /*set the color you want here*/
// }

// ******************************************BOX RESULTS************************************************************
#city{
    // background-color: rgba($color: rgb(138, 56, 17), $alpha: 0.70);    
    background-color: rgba($color: rgb(110, 50, 22), $alpha: 0.80);    
}
#temp{
    background-color: rgba($color: blue, $alpha: 0.75);
}
#humidity{
    background-color: rgba($color: green, $alpha: 0.5);
}
#wind{
    background-color: rgba($color: green, $alpha: 0.90);
}
#pressure{
    background-color: rgba($color: green, $alpha: 0.90);
}
#visibility{
    background-color: rgba($color: green, $alpha: 0.65);
}
#feels_like{
    background-color: rgba($color: green, $alpha: 0.45);
}
#description{
    background-color: rgba($color: green, $alpha: 0.80);
}


