header{
    color: $headerColor; 
    font-family: $fontFamNormalText;   
    }

header{
    background-image: url(../../public/img/imgHeader.jpg);
    // background-size: cover;
    // background-size: 50% 50%;
    // background-size: contain;
    background-size: cover;
    background-repeat: no-repeat;
    // min-height: 30vh;
    // min-height: fit-content;
    // aspect-ratio: 2/1;
    aspect-ratio: 16/5;

    
    // object-fit:cover;
    background-position: 50%;
       
    // min-height: 30vmax;
    // min-height: 70vmin;
    text-align: right;
    padding-right: 20px;
    padding-top: 20px;
    }    

// header img{
//     max-width: 100%;
//     height: auto;
//     background-size: cover;
// }
// ****************************centre le text du header*****************************************
// header .row{
//     min-height: 30vh;
// }