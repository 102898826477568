.divCitations{
    padding-top: 4rem;
}

.citationsBgcColorTitle{
    color: red !important;
    font-size: 3rem;

}

.citationsBgcColor{
    // background-color: red;
    // background-color: #262626;
    // background-color: rgba($color: green, $alpha: 0.30);
    // padding-top: 5rem;
    background-color: rgba($color: #262626, $alpha: 0.30);
    // color: $headerColor; 
    text-align: center;
    color: beige;

    font-family: $fontFamNormalText;  
}

.citationsBgc{
    background-color: #262626;
    background-image: url(../../public/img/imgCitationsWheat_PNG104.png);
    // background-size: cover;
    // background-size: 50% 50%;
    // background-size: contain;
    background-size: cover;
    background-repeat: no-repeat;
    
    min-height: 100vh;
    // min-width: 100vw;
   


    // min-height: fit-content;
    // aspect-ratio: 2/1;
    // aspect-ratio: 16/5;

    
    // object-fit:cover;
    // background-position: 50%;
       
    // min-height: 30vmax;
    // min-height: 70vmin;
    text-align: right;
    padding-right: 20px;
    padding-top: 20px;
    
}