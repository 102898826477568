
// **********************************BODY ET TESTS*****************************************
$bgcColor : beige;

$primaryColor : rgb(0, 255, 149);

$secondaryColor : indigo;


// *************************************COLOR HEADER****************************************************
// $headerColor : grey;
$headerColor : rgb(129, 116, 116);

// *************************************COLORS****************************************************
 

        // ****************COLORS TITLES***********
$titleBigColor : #53b54b;
$titleParagraph : #0b4705;

$h1Color : black;
// ************************************CAROUSEL**************************************************
$timerCarousel1 : 5000;
$timerCarousel2 : 80000;





