// @import "/node_modules/bootstrap/scss/bootstrap.scss";

// ***************************************HERE THE SCSS CODE*************************************
// ******************************FONTS TYPOGRAPHY**************************************
.normalText{
    font-family: $fontFamNormalText;
}
.Quotation{
    font-family: $fontFamQuotation;
}
.title1{
    font-family: $fontFamTitle1;  
}
.title2{
    font-family: $fontFamTitle2;  
}
.title3{
    font-family: $fontFamTitle3;  
}
.title4{
    font-family: $fontFamTitle4;  
}
// *************************************************GLOBAL CSS********************************************
body{
    // color: red;    
    background-color: $bgcColor;
    font-family: $fontFamNormalText;   
}

h1{
    // color: green; 
    color: $h1Color; 
    font-family: $fontFamNormalText;   
    
}
// ********************************************GLOBAL COLOR CHAPTERS*******************************
.mixinContainerColor{
    @include mixinContainerColor();
}
// *************************************************TITLES AND MIXIN TITLES********************************************
.mixinTitleBig{
    @include mixinTitleBig();  
}  
.mixinTitleParagraph{
    @include mixinTitleParagraph();  
}  

.titleCentered{
    text-align: center
}
.titleLeft{
    text-align: left;
}
.titleRight{
    text-align: right
}
.titleJustify{
    text-align: justify;
}
// ************************************TITLE BGC RESPONSIVE************************************************
// **********TITLE BGC EN DESSOUS DE 768PX***************
@media screen and (max-width: 767px) {
    .bgcTitleBgc{
    background-color: rgba($color: green, $alpha: 0.30);
    color: beige !important;    
    // color: rgb(32, 30, 30) !important;    
    line-height: 1;
    // ***************for middle place center title*********
    // width: max-content; 
    // max-width: fit-content;
    // text-align: right !important;
    display: flex;
    float: right;    
    padding-right: 0px;
    
    // margin: 0 !important;
    // margin: auto;
}
}







.noMarginBottom{
    margin-bottom: 0px;
}


// **************************************NAVBAR--->VOIR PARTIALS NAV*************************************************

// ***********************************SECTIONS***********************************************
.divImageSection {   /* fixe la taille 400X300px de la div de l'image de section pour qu'elle ne soit pas trop enorme  */
    width: 370px;
    // height: 350px;
    height: auto;
}
.imageSection {           /* permet que l'image de section s'adapte quand en responsive on reduit largeur ecran */
    /* margin-right: 400px; */
    width: 100%;
}

// **************************************************HEADER*********************************************************
.centerColumn{
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    // align-content: stretch;
    // align-items: stretch;
    align-self: flex-end;
}

// *****************************************SECTION HISTORIQUE CULTURES*****************************************

// ************************************PUCES*************************************************************
// ul li {
    //     // list-style-type: circle; /* style de la puce */
    //     // list-style-position : inside; /* position de la puce */
    //     list-style-image: url("../../../public/img/list/listCow.png"); /* image de la puce */
    //     // list-style-image: url("../../../public/img/cow_PNG50576.png"); /* image de la puce */
    //     // list-style-image: image("../../../public/img/GardiensBallotte.jpg");
    //     // list-style-image: url("../../media/examples/rocket.svg");
    //     }
    
    
    .puce1 ul li {
        /* list-style: disc url(images/puce.png) inside; */
        list-style: disc url(/public/img/puceListBle22px.png) inside;
        /* font-weight: bold; */
    }
    .puce2 ul li {
        /* list-style: disc url(images/puce.png) inside; */
        list-style: disc url(/public/img/puceListCow.png) inside;
        /* font-weight: bold; */
    }    
    
    // ****************************************TABLE ASSOLEMENT*************************************
/* graphisme et couleurs */

thead, tfoot {
    // background: url(/public/img/TableAssolementLeopardskin.jpg);
    background: url(/public/img/TableAssolementBackground.png);
    color: white;
    text-shadow: 1px 1px 1px black;
  }
  
  thead th, tfoot th{
      padding-left: 5px;
    } 
    
    
    tbody {
        // background: url(/public/img/TableAssolementLeopardskin.jpg);
        background: url(/public/img/TableAssolementBackground4.png);
        // filter: brightness(90%);
        // color: white;
        // text-shadow: 1px 1px 1px black;
        
    }
    tbody th{
        padding-left: 10px;
    }
    tbody td{
    padding-left: 10px;
}

.tableAssolement th{
    padding-right: 10px;
}

//   thead th, tfoot th, tfoot td {
    //     background: linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5));
//     border: 3px solid green;
//   }
// ****************************************LOGO LAIT*************************************
.pSodiaal{
    margin-bottom: 0;
}
.logoLittle img{
    max-width: 60px;
    max-height: 60px;    
}
// *****************************************************FOOTER*******************************************
.byeFontaineMacon img{
    height: 200px;
}
.footerSeparation{
    height: 10px;
    // background-color: green;
}
.colorLinkNone{
    text-decoration: none;
}
// .interLign{
//     // height: 1.5rem;
//     // height: 3rem;
//     height: 0rem;
// }
// **********FOOTER INTER AU DESSUS DE 768PX***************
.interLign{
    height: 3rem; 
}
// **********FOOTER INTER EN DESSOUS DE 768PX***************
@media screen and (max-width: 767px) {
    .interLign{
        height: 0rem;
          
    }
}
// **********FOOTER INTERT AU DESSUS DE 990PX***************
@media screen and (min-width: 992px) {
    .interLign{
        height: 1.5rem;
          
    }
}
.remerciements{
    // margin-bottom: 0;
    margin: 0;
    padding: 0;
    margin-left: 4px;    
}


// **************************************************COLOR FOOTER************************
$FooterColor : #53b54b;

footer{
    background-color: $FooterColor;
}
.TitleFooter{
    // font-stretch: 50% !important;
    color: yellow;
    // font-stretch: ultra-condensed !important; 
    // font-size: large; 
      
    
}

.divImageFooter {   /* fixe la taille 400X300px de la div de l'image de section pour qu'elle ne soit pas trop enorme  */
    // width: 320px;
    // width: min-content;
    width: 320px;
    // height: 350px;
    height: auto;
}

.imageFooter{
    width: 100%;
    position: absolute;
}
// **********AU DESSUS DE 768PX***************
.containerFooter img{
    max-block-size: 200px;
    width: 100%;
    // width: auto; 
    
}
// **********EN DESSOUS DE 768PX***************
@media screen and (max-width: 767px) {
    .containerFooter img{
        max-block-size: 200px;
        // width: 100%;
        width: auto;    
    }
}
// **********AU DESSUS DE 1400PX***************
@media screen and (min-width: 1400px) {
    .containerFooter img{
        max-block-size: 200px;
        // width: 100%;
        // width: auto;
        width: auto;
    }
}
// *********************************WHEAT IMG**********************************************

.bgcWheat{
    background-image: url(/public/img/Footerwheat_PNG53.png);  
    background-size: cover;
    background-size: 350px;
    background-repeat: no-repeat;
    // background-position: 50%;
    // background-position: 0%;
    // background-position-x: left;
    background-position-y: 100%;  
    // background-color: yellow;  
    // background-color: transparent;
    
}

// .containerTransparency{
    //     z-index: -3 !important;
    // }
    
    // ******************************************CARDS**********************************************
    
    .card{
        background-color: #f5f5f5;    
        align-items: center !important;
        justify-content: center !important;
    }
    
    .card img{
        width: 200px;
        // height: 350px;
        // height: auto;
        border-radius: 50%;
    }
    
// ***************************************CAROUSEL*******************************************
.carouselFerme{
    // align-items: center !important;
    // justify-content: center !important;
    position: relative;
    margin: auto;
}
.carousel{
    max-width: 990px;
    // height: auto;
    // width: 100%;
    // min-height: 48rem;   
}
// ******************CAROUSEL HORIZONTAL CENTER*******************
.carousel {
    // width: 200px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

// **********AU DESSUS DE 990PX***************
@media screen and (min-width: 990px) {
    .carousel{    
        min-height: 48rem; 
    }
}
// **********EN DESSOUS DE 990PX***************
@media screen and (max-width: 989px) {
    .carousel{    
        min-height: 34rem; 
    }
}
// **********EN DESSOUS DE 768PX***************
@media screen and (max-width: 767px) {
    .carousel{    
        min-height: 25rem; 
    }
}
// .cadreCarousel{
    //     min-height: 48rem
    // }
    // .cadreCarousel{
        //     // min-height: 90vh
        //     min-height: 
        // }
// ****************************************************AU REVOIR***************************************************
.divImageSectionMiddle{
    width: 450px;
    // height: 350px;
    // height: auto;
    // margin: auto;
}
.containerLogo{
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    // align-items: center;
    margin: auto;
}
// ***********************************LOGOS OVER DIAPORAMA*********************************
.imageSectionLogoSeparate{
    width: 250px;
    // width: 20%;
    margin: auto;
}
// **********EN DESSOUS DE 768PX LOGOS OVER DIAPORAMA***************
@media screen and (max-width: 540px) {
    .imageSectionLogoSeparate{
        width: 200px;
        // width: 20%;
        margin: auto;
    }
}

        
        
        
// ******************************************************************************************************************
// ***********************************************ESSAIS TESTS********************************************************    
// ******************************************************************************************************************
.testMixinBoxTestMixin{
    @include boxtestmixin();  
}    

.mixinTitleMedium{
    @include mixinTitleMedium();  
}  

.begin{
    color: $primaryColor;
&:hover {
color: $secondaryColor;
}
}