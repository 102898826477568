// @import "../../node_modules/bootstrap/scss/bootstrap.scss";

/* *******************IMPORTATION POLICES GOOGLE FONTS WITH ALL THEIR STYLES************************ */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
// *********TO TRY CURSIVE FONTS***************
@import url('https://fonts.googleapis.com/css2?family=Marck+Script&display=swap');

// *************************************PARTIALS NORMALIZE**********************************************************
@import "partials/normalize";

// *********************IMPORT BOOTSTRAP******************************************************************************
// Custom.scss
// Option A: Include all of Bootstrap

// Include any default variable overrides here (though functions won't be available)
// @import "/node_modules/bootstrap/scss/bootstrap.scss";
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";


// Then add additional custom code here


// ******************************************PARTIALS**********************************************************
// @import "partials/normalize";
@import "partials/variables";
@import "partials/typography";
@import "partials/nav";
@import "partials/header";
@import "partials/boxtestmixin";
@import "partials/mixinTitle";
@import "partials/parallax";
@import "partials/meteo";
@import "partials/citations";

// @import "../../node_modules/bootstrap/scss/bootstrap.scss";




// ***********************************IMPORT SCSS FROM APP.SCSS **********************************************************
@import "app.scss";

// *************************************************************************************************************
